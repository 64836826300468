import React from "react";
import MainLayout from "../components/Layout/MainLayout";
import styled from "styled-components";
import { H1 } from "../components/Styles/Typography";
import { graphql } from "gatsby";
import { BackgroundImage, FallbackBackgroundImage } from "../components/Styles/BackgroundImage";
import fallbackImage from "../../static/fallback-image.jpg";

const FundingFormPage = ({ data, location }) => {
  const {
    content,
    featured_media,
    title,
  } = data.allWordpressPage.edges[0].node;

  return (
    <MainLayout location={location}>
      <ImgWrapper className="container-fluid">
        {featured_media && featured_media.localFile ? (
          <BackgroundImage
            sizes={featured_media.localFile.childImageSharp.fluid}
          />
        ) : (
          <FallbackBackgroundImage
            style={{ backgroundImage: `url(${fallbackImage})` }}
          />
        )}
        <IntroBody>
          <H1>{title}</H1>
        </IntroBody>
      </ImgWrapper>
      <Paragraph className="container">
        <div className="row">
          <div
            className="col-xs-12 col-md-8 col-md-offset-2"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </Paragraph>
    </MainLayout>
  );
};

const ImgWrapper = styled.div`
  height: 50vh;
  width: 100%;
  top: -100px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IntroBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  position: relative;
  padding-top: 3rem;
`;

const Paragraph = styled.div`
  margin-bottom: 5.5rem;
  text-align: justify;
  

  small {
    display: block;
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 2rem;
  }

  h4 {
    margin: 1.5rem 0;
  }

  strong {
    font-weight: bold;
  }
`;

export const TermsQuery = graphql`
  query TermsQuery {
    allWordpressPage(filter: { slug: { regex: "/terms-of-service/" } }) {
      edges {
        node {
          slug
          title
          content
          featured_media {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default FundingFormPage;
